/* Common CSS */
.container {
  max-width: 1140px !important;
}

a {
  text-decoration: none !important;
  outline: none;
}

p {
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
}

.row>* {
  position: relative;
}

h5 {
  font-size: 23px !important;
  font-weight: 500 !important;
}

h6 {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-13 {
  font-size: 13px;
}

.f-14 {
  font-size: 14px;
}

.f-15 {
  font-size: 15px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-19 {
  font-size: 19px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-23 {
  font-size: 23px;
}

.f-24 {
  font-size: 24px;
}

.text-muted {
  color: #9fa1a4 !important;
}

.text-primary {
  color: #0473a6 !important;
}

.text-info {
  color: #5d91c1 !important;
}

.text-success {
  color: #61ce70 !important;
}

.text-danger {
  color: #ff413f !important;
}

.btn {
  font-weight: 600;
}

.btn-outline-primary {
  color: #0473a6 !important;
  border: 2px solid #0473a6 !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary .open>.dropdown-toggle.btn-primary {
  background: #0473a6;
  border: 2px solid #0473a6;
  color: #fff !important;
}

hr {
  border: 1px solid #694FEF;
}

/* Avatar CSS */

.avatar-sm {
  height: 4.6rem;
  width: 4.6rem !important;
}

.avatar-title {
  padding: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Navbar CSS */

.navbar {
  background-color: #694FEF;
}

.nav-item a {
  color: white;
  font-weight: 400;
  display: inline-block;
  position: relative;
}

.nav-item a:active,
.nav-item a:focus,
.nav-item a:hover {
  color: #fff;
}

.nav-item a:active::after,
.nav-item a:focus::after,
.nav-item a:hover::after {
  content: '';
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  /* Set the width of the line */
  height: 2px;
  /* Set the height (thickness) of the line */
  background-color: white;
}


.navbar-toggler {
  color: #fff !important;
}

.navbar-toggler:focus {
  box-shadow: none;
}

/* Footer CSS */
.footer_section {
  background-color: #694FEF;
}

/* Home Page CSS */

.home-section {
  min-height: 64vh;
}

.sub-container {
  padding: 10px;
  background-color: #222341;
  border: 1px solid #404380;
  border-radius: 12px;
}

.game-box {
  height: 200px;
  width: 200px;
  background-color: #d9d9d9;
  color: #000;
  border-radius: 12px;
  padding: 10px;
}

.game-box i {
  font-size: 100px;
  color: #26284c;
}

/* scrollbar css */
.sub-container::-webkit-scrollbar {
  height: 5px;
}

.sub-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* contact Us CSS */

.background-white {
  background-color: #ffffff;
  color: #26284c;
  padding: 15px 0px;
}

/* knife game css */

/* .knifeGame{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64vh;
} */